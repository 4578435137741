
import React from 'react'

import Layout from '../components/layout/layout'


const read: React.FC<Props> = ({ location, data }: Props) => {

  return (
    <Layout location={location}>

hi
    </Layout>
  )
}

export default read

